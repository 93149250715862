import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { selectCasinoFavoritesList } from '@mwl/core-lib';

import { useAuthRoutes, useTypedSelector } from '@/hooks';

import type { NavMenuItem } from '@/types';

import { isMenuItemActive } from '../CasinoLayout.utils';

export function useMenuItems(navMenu: Array<NavMenuItem>) {
  const { pathname, query } = useRouter();
  const items = useAuthRoutes(navMenu);
  const favorites = useTypedSelector(selectCasinoFavoritesList);
  const favoritesCount = Object.values(favorites).filter(Boolean).length;

  return useMemo(() => {
    return items
      .filter(({ mobile, slug }) => {
        if (slug === 'favorite' && !mobile) {
          return favoritesCount > 0;
        }

        return !mobile;
      })
      .map((menuItem) => {
        const isActive = isMenuItemActive(menuItem.href, pathname, query);
        return {
          ...menuItem,
          isActive,
          count: menuItem.slug === 'favorite' && favoritesCount ? favoritesCount : undefined,
        };
      });
  }, [items, pathname, query, favoritesCount]);
}
