import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'next-i18next';
import type Swiper from 'swiper';
import {
  getClientPlatform,
  getGameBaseUrl,
  getTestProps,
  loadWinnersRequest,
  numberFormatter,
  selectCasinoWinners,
  selectCasinoWinnersIsLoading,
  selectUserCurrency,
  selectUserCurrencySymbol,
  sendAnalyticsData,
} from '@mwl/core-lib';
import { useIsVisibleWatch } from '@mwl/ui';

import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import { useTypedDispatch, useTypedSelector } from '@/hooks';

import { Loader } from '../Loader/Loader';
import { Slider } from '../Slider/Slider';

import { sliderParams } from './Winners.data';
import type { WinnersProps } from './Winners.types';

import styles from './Winners.module.scss';
import variables from '@/styles/helpers/export.module.scss';

const platform = getClientPlatform();

const Winners: FC<WinnersProps> = ({ className, productType, ...props }) => {
  const winnersRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('casino');

  const dispatch = useTypedDispatch();

  const visibilityMargin = isMobile ? undefined : `-${variables.headerHeight}`;

  const isVisible = useIsVisibleWatch(winnersRef.current || undefined, {
    isTrackVisibility: true,
    options: { rootMargin: visibilityMargin },
  });

  const currency = useTypedSelector(selectUserCurrency);
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);
  const winners = useTypedSelector(selectCasinoWinners);
  const winnersLoading = useTypedSelector(selectCasinoWinnersIsLoading);

  const winnerClassName = styles.winner;

  const sliderAutoplayHandler = (swiper: Swiper) => {
    const winner = winners[swiper.realIndex];

    const analyticsData = {
      cls: winnerClassName,
      count: '6',
      position: '1',
      listings: 'current_winners',
      game_id: winner.gameId,
    };

    if (isVisible) {
      sendAnalyticsData('currentWinners_game_show', analyticsData);
    }
  };

  useEffect(() => {
    if (!currency) {
      return;
    }

    dispatch(
      loadWinnersRequest({
        platform,
        currency,
        productType,
      }),
    );
  }, [currency, dispatch, productType]);

  const baseUrl = getGameBaseUrl({
    productType,
    casinoGameUrl: routes.casino.game,
    liveCasinoGameUrl: routes.liveCasino.game,
  });

  if (!winners.length) {
    return null;
  }

  return (
    <div {...getTestProps(props)} ref={winnersRef} className={cn(styles.root, className)}>
      <div className={styles.header}>{t('winners.message', "Now they're winning!")}</div>
      <div className={styles.winners}>
        {winnersLoading && <Loader />}
        {!winnersLoading && (
          <Slider {...sliderParams} className={styles.slider} onAutoplay={sliderAutoplayHandler}>
            {winners.map((winner, index) => (
              <div className={winnerClassName} key={`${winner.name}${index}`}>
                <div className={styles.image}>
                  <Image
                    src={winner.gameImage}
                    width={50}
                    quality={70}
                    alt={winner.gameName}
                    fill
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div className={styles.title}>{winner.gameName}</div>
                <div className={styles.subtitle}>
                  <span className={styles.name} title={winner.name}>
                    {winner.name}
                  </span>
                  <span title={winner.amount} className={styles.amount}>
                    <span>{currencySymbol}</span>{' '}
                    <span>{numberFormatter(Number(winner.amount), { maximumFractionDigits: 2 })}</span>
                  </span>
                </div>

                <Button
                  className={styles.actions}
                  href={{
                    pathname: baseUrl,
                    query: { gameId: winner.gameId, mode: 'real' },
                  }}
                  as="link"
                  variant="text"
                >
                  <Button as="button" variant="fulfilled" color="red" className={cn(styles.button)} fontWeight="bold">
                    {t('game.mode.play', 'Play')}
                  </Button>
                </Button>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export { Winners };
