import { type FC } from 'react';
import { useRouter } from 'next/router';
import { sendAnalyticsData, useSwipe } from '@mwl/core-lib';

import {
  casinoNavMenu,
  liveCasinoNavMenu,
  routes,
  sidebarTopSectionNavMenuCasino,
  sidebarTopSectionNavMenuLiveCasino,
} from '@/constants';

import { useMenuItems } from '../../hooks/useMenuItems';
import { MenuItem } from '../MenuItem/MenuItem';

import type { NavigationProps } from './Navigation.types';

import styles from './Navigation.module.scss';

export const Navigation: FC<NavigationProps> = ({ className }) => {
  const router = useRouter();

  const menuItems = router.asPath.includes(routes.liveCasino.home) ? liveCasinoNavMenu : casinoNavMenu;

  const menuItemsTop = router.asPath.includes(routes.liveCasino.home)
    ? sidebarTopSectionNavMenuLiveCasino
    : sidebarTopSectionNavMenuCasino;

  const navMenu = useMenuItems([...menuItemsTop, ...menuItems]);

  const handleMenuSwipe = (position: 'left' | 'right') => {
    sendAnalyticsData('casinoMenu_swipe', { position });
  };

  const { ref: scrollableRef } = useSwipe({ onSwipe: handleMenuSwipe });

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.root, className)}>
        <div className={styles.container}>
          <div ref={scrollableRef} className={styles.scrollable}>
            {navMenu
              ?.sort((menuItem) => (menuItem.isActive ? -1 : 1))
              .map((menuItem) => (
                <MenuItem {...menuItem} id={menuItem.key} key={menuItem.key} scrollableRef={scrollableRef} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
